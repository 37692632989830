import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import {
  useCartItemsContext,
  useCartItemsContextAction,
} from "../../context/CartItemsProvider";
import Counter from "../Counter/Counter";

const FoodCard = ({
  id,
  image,
  description,
  title,
  rate,
  price,
  count,
  isAlcoholic,
  isFood,
  shoppingMode,
  category,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(4);
  const dispatch = useCartItemsContextAction();
  const [isWideImage, setIsWideImage] = useState(false);
  useEffect(() => {
    setValue(rate ? rate : 0);
  }, []);

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        setIsWideImage(img.width > img.height);
      };
    }
  }, [image]);

  const itemDispatcher = (type) => {
    const item = {
      id: id,
      image: image,
      description: description,
      title: title,
      rate: rate,
      price: price,
      count: count,
      isAlcoholic: isAlcoholic,
      category: category,
      isFood: isFood,
    };
    // Use dispatch to add the item to the cart context
    dispatch({ type: type, payload: item });
  };

  return (
    <Card
      sx={{
        width: 300,
        borderRadius: 3,
        // backgroundColor: "#ffedd5 !important",
      }}
    >
      {image && (
        <CardMedia
          component="img"
          height="300"
          image={image}
          alt={title}
          sx={{
            objectFit: "contain",
          }}
        />
      )}
      <CardContent sx={{ height: 200 }}>
        <Typography variant="h6" color="text">
          {title}
        </Typography>
        <Typography variant="body" color="text">
          {description}
          {isAlcoholic && (
            <Typography sx={{ color: "red", fontSize: 14 }}>
              *You must order food in order to add alcoholic items.
            </Typography>
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {"$ " + price}
        </Typography>
      </CardContent>

      <CardActions
        sx={{
          height: 120,
          display: "flex",
          alignItems: "end",
          justifyContent: "center",
        }}
        disableSpacing
      >
        {shoppingMode && (
          <Counter
            isAlcoholicDrink={isAlcoholic}
            count={count}
            itemDispatcher={itemDispatcher}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default FoodCard;
