import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  useCartItemsContext,
  useCartItemsContextAction,
} from "../../context/CartItemsProvider";
import Counter from "../../components/Counter/Counter";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { MdOutlineDelete } from "react-icons/md";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const CartItems = useCartItemsContext();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subTotal, setSubtotal] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [customerApt, setCustomerApt] = useState("");
  const [orderInstructions, setOrderInstructions] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [postalCodeErr, setPostalCodeErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Online");
  // const [allCustomerDetails, setAllCustomerDetails] = useState("");
  const dispatch = useCartItemsContextAction();
  useEffect(() => {
    checkOpeningHours();
    setLoading(false);
  }, []);

  useEffect(() => {
    calcTotalPrice(discountCode);
  }, [deliveryFee]);

  useEffect(() => {
    calcDelivaryFee(postalCode);
  }, [subTotal]);

  useEffect(() => {
    setSelectedItems(CartItems);
  }, [CartItems]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      let matchedItem = selectedItems.find(
        (category) => category.isFood === true
      );
      if (!matchedItem) {
        let filteredItems = selectedItems.filter((item) => !item.isAlcoholic);
        // Dispatch to set the count of filtered alcoholic items to zero
        selectedItems.forEach((item) => {
          if (item.isAlcoholic) {
            itemDispatcher("removeItem", item.id); // Assuming this action updates the item count
          }
        });
        setSelectedItems(filteredItems);
        toast.error("You must order food alongside alcoholic items.");
        setShowAlert(true);
      }
    }
    calcDelivaryFee(postalCode);
    calcTotalPrice(discountCode);
  }, [selectedItems]);

  const itemDispatcher = (type, itemId) => {
    let item = selectedItems.find((item) => item.id === itemId);
    dispatch({ type: type, payload: item });
  };

  const calcTotalPrice = (coupon) => {
    let total = 0;
    let discountAmount = 0;
    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      total += item.price * item.count;
    }
    setSubtotal(total.toFixed(2));
    let tax = total * 0.15;
    if (coupon === "LIMITED10") {
      total = 0;
      for (let i = 0; i < selectedItems.length; i++) {
        const item = selectedItems[i];

        if (
          item.category &&
          (item.category === "Main Menu" || item.category === "Starter")
        ) {
          discountAmount += item.price * 0.1 * item.count;
          total += (item.price - item.price * 0.1) * item.count;
        } else total += item.price * item.count;
      }
    }
    setDiscount(discountAmount.toFixed(2));

    setTaxAmount(tax.toFixed(2));
    let taxedTotal = total + tax;
    total = taxedTotal + deliveryFee;
    setTotalPrice(total.toFixed(2));
  };

  const handleCustomerDetailsChange = (e) => {
    let inputName = e.target.name;
    if (inputName === "customerName") {
      setNameErr(false);
      setCustomerName(e.target.value);
    }
    if (inputName === "customerAddress") {
      setAddressErr(false);
      setCustomerAddress(e.target.value);
    }
    if (inputName === "customerApt") {
      setCustomerApt(e.target.value);
    }
    if (inputName === "orderInstructions") {
      setOrderInstructions(e.target.value);
    }
    if (inputName === "customePhoneNumber") {
      setPhoneErr(false);
      setCustomerPhoneNumber(e.target.value);
    }
    if (inputName === "postalCode") {
      let postal = e.target.value;
      setPostalCodeErr(false);
      setPostalCode(postal);
      calcDelivaryFee(postal);
    }
    if (inputName === "discountCode") {
      let code = e.target.value;
      setDiscountCode(code);
      calcTotalPrice(code);
    }
  };

  const calcDelivaryFee = (postal) => {
    let strWithoutSpaces = postal.replace(/\s+/g, ""); // Removes all spaces
    let firstThreeChars = strWithoutSpaces
      .slice(0, 3) // Get first 3 characters
      .split("") // Split string into array
      .map((char) => {
        // If it's a letter, change to lowercase; else, leave it unchanged
        return /[a-zA-Z]/.test(char) ? char.toLowerCase() : char;
      })
      .join(""); // Join the array back into a string
    if (postal.length >= 3) {
      if (firstThreeChars === "a1c" && subTotal < 30) {
        setDeliveryFee(5);
      } else if (
        (firstThreeChars === "a1b" || firstThreeChars === "a1e") &&
        subTotal > 30
      ) {
        setDeliveryFee(5);
      } else if (
        (firstThreeChars === "a1a" || firstThreeChars === "a1g") &&
        subTotal > 30
      ) {
        setDeliveryFee(10);
      } else if (
        (firstThreeChars === "a1b" ||
          firstThreeChars === "a1e" ||
          firstThreeChars === "a1a" ||
          firstThreeChars === "a1g") &&
        subTotal < 30
      ) {
        setDeliveryFee(10);
      }
    } else if (postal.length < 3) {
      setDeliveryFee(0);
    }
  };

  const handleSelectPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleCheckout = async () => {
    if (isClosed) {
      toast.error(
        "The restaurant is currently closed. Please try again later."
      );
      return;
    }
    if (discountCode) {
      if (discountCode !== "LIMITED10") {
        toast.error("Discount Code is not valid.");
        return;
      }
    }
    let isValid = validateCustomerDetails();
    try {
      if (isValid) {
        let apt = "";
        let inst = "";
        if (customerApt) apt = customerApt + ", ";
        if (orderInstructions)
          inst = "\nInstructions: " + orderInstructions + ", ";
        setLoading(true);
        let allCustomerDetails =
          customerName +
          ", " +
          customerPhoneNumber +
          ", " +
          customerAddress +
          ", " +
          postalCode +
          ", " +
          apt +
          inst;

        // Append selectedItems to the customer details string
        let itemsDetails = selectedItems
          .map((item) => `${item.title} * ${item.count}`)
          .join("\n");

        allCustomerDetails += "\nOrder Details: \n" + itemsDetails;
        let url = "";
        let body = {};
        let total = totalPrice;
        if (paymentMethod === "Cash On Delivery")
          url = "https://thecookhouse.ca/api/COD_payment";
        else url = "https://thecookhouse.ca/api/set_payment";
        if (paymentMethod === "Cash On Delivery") {
          body = {
            OrderData: allCustomerDetails + "\n Total Price: " + total,
          };
        } else
          body = {
            name: allCustomerDetails,
            location_id: "59GKRKPHGXJ7C",
            price_money: total * 100,
          };
        axios
          .post(url, body)
          .then((response) => {
            const data = response.data;
            if (paymentMethod === "Cash On Delivery") {
              if (data === "OK") {
                selectedItems.forEach((item) => {
                  itemDispatcher("removeItem", item.id);
                });
                navigate("/confirmation"); // Redirect to ConfirmationPage
              } else {
                toast.error(
                  "Sorry! We have a technical issue. Please try again or contact the restaurant."
                );
              }
            } else {
              if (data.payment_link && data.payment_link.long_url) {
                window.location.href = data.payment_link.long_url;
              } else {
                console.log("Payment link not found.");
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              "Sorry! We have a technical issue. Please try again or contact the restaurant."
            );
          });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Please enter you details.");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formatCanadianNumber = (phoneNumber) => {
    // If the number starts with a 10-digit Canadian number (no +1), add +1
    const canadianNumberPattern = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    if (canadianNumberPattern.test(phoneNumber)) {
      return `+1${phoneNumber}`;
    }
    return phoneNumber;
  };

  const validatePhoneNumber = (phoneNumber) => {
    phoneNumber = formatCanadianNumber(phoneNumber);
    // Parse and validate phone number using libphonenumber-js
    try {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
      if (!parsedPhoneNumber) return false; // Invalid format

      // Return true if the number is valid and passes the validation
      return parsedPhoneNumber.isValid();
    } catch (error) {
      return false; // Invalid format or error
    }
  };

  const validateCustomerDetails = () => {
    let isValid = true;
    let isPhoneNumberValid = !validatePhoneNumber(customerPhoneNumber);
    if (!customerName) {
      setNameErr(customerName ? false : true);
      isValid = false;
    }
    if (!postalCode) {
      setPostalCodeErr(postalCode ? false : true);
      isValid = false;
    }
    if (isPhoneNumberValid) {
      setPhoneErr(isPhoneNumberValid);
      isValid = false;
    }
    if (!customerAddress) {
      setAddressErr(customerAddress ? false : true);
      isValid = false;
    }
    if (deliveryFee === 0) {
      if (isValid) toast.error("Postal code is not valid");
      isValid = false;
    }

    return isValid;
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const checkOpeningHours = () => {
    let closed = false;

    // Get current time
    const now = new Date();
    const day = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const hour = now.getHours(); // 0-23 hour format

    // Define Hours for each day of the week
    const openingHours = {
      0: { open: 11, close: 20 }, // Sunday: 11 a.m. – 8 p.m.
      1: null, // Monday: Closed
      2: { open: 11, close: 21 }, // Tuesday: 11 a.m. – 9 p.m.
      3: { open: 11, close: 21 }, // Wednesday: 11 a.m. – 9 p.m.
      4: { open: 11, close: 21 }, // Thursday: 11 a.m. – 9 p.m.
      5: { open: 11, close: 21 }, // Friday: 11 a.m. – 9 p.m.
      6: { open: 11, close: 21 }, // Saturday: 11 a.m. – 9 p.m.
    };

    const hoursToday = openingHours[day];

    // Check if the restaurant is closed today
    if (!hoursToday) {
      closed = true;
    } else {
      const { open, close } = hoursToday;
      if (hour < open || hour >= close) {
        closed = true;
      }
    }

    setIsClosed(closed);
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent overlay
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000, // Make sure it appears on top
          }}
        >
          <CircularProgress disableShrink />
          <Typography sx={{ ml: 2 }} variant="body" color="text">
            Please wait...
          </Typography>
        </div>
      ) : (
        <div style={{ pointerEvents: loading ? "none" : "auto" }}>
          {showAlert && (
            <Alert severity="error" onClose={handleCloseAlert}>
              Please note: Alcoholic items cannot be purchased individually and
              must be ordered with food.
            </Alert>
          )}
          <Grid
            sx={{ display: "flex", justifyContent: "center" }}
            container
            spacing={2}
          >
            <Grid item md={8} lg={5} className="cartItems">
              <Box
                component="form"
                sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
                noValidate
                autoComplete="off"
              >
                <Box sx={{ m: 1 }}>
                  <TextField
                    required
                    error={nameErr}
                    helperText="Please enter your name."
                    onChange={(e) => handleCustomerDetailsChange(e)}
                    value={customerName}
                    name="customerName"
                    id="Name-input"
                    label="Name"
                    placeholder="Jack"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <TextField
                    required
                    error={phoneErr}
                    helperText="Please enter a correct phone number. Use digits only, without spaces or special characters."
                    onChange={(e) => handleCustomerDetailsChange(e)}
                    value={customerPhoneNumber}
                    name="customePhoneNumber"
                    id="Phone-input"
                    label="Phone Number"
                    placeholder="+11230000"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <TextField
                    required
                    error={addressErr}
                    helperText="Please enter your address"
                    onChange={(e) => handleCustomerDetailsChange(e)}
                    value={customerAddress}
                    name="customerAddress"
                    id="Address-input"
                    label="Address"
                    placeholder="20 Example Avenue"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <TextField
                    required
                    error={postalCodeErr}
                    helperText="Please enter your postal code"
                    onChange={(e) => handleCustomerDetailsChange(e)}
                    value={postalCode}
                    name="postalCode"
                    id="postalCode-input"
                    label="Postal Code"
                    placeholder="A1A AAA"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <TextField
                    // error
                    onChange={(e) => handleCustomerDetailsChange(e)}
                    value={customerApt}
                    name="customerApt"
                    id="Apt-Number-input"
                    label="Apt Number"
                    placeholder="12"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <TextField
                    // error
                    onChange={(e) => handleCustomerDetailsChange(e)}
                    value={orderInstructions}
                    name="orderInstructions"
                    id="Instructions-input"
                    label="Instructions"
                    placeholder="Leave at my door"
                  />
                </Box>
              </Box>
              <Box>
                <h3>Order Details</h3>
                {selectedItems.length > 0 ? (
                  selectedItems.map((item) => (
                    <Card
                      key={item.id}
                      sx={{ display: "flex", mb: 1, borderRadius: 3 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xl={3} lg={4} md={5} sm={5} xs={12}>
                          {item.image && (
                            <CardMedia
                              component="img"
                              // sx={{ width: "200px" }}
                              image={item.image}
                              alt={item.title}
                              sx={{
                                objectFit: "contain",
                                height: "180px",
                              }}
                            />
                          )}
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                          item
                          xl={9}
                          lg={8}
                          md={7}
                          sm={7}
                          xs={12}
                        >
                          <Box>
                            <CardContent>
                              <Typography component="div" variant="h6">
                                {item.title}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                color="text"
                                component="div"
                              >
                                {"$ " + item.price}
                              </Typography>
                            </CardContent>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "space-between",
                              mr: 1,
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                itemDispatcher("removeItem", item.id)
                              }
                              sx={{ mb: 1, ml: 1 }}
                              variant="outlined"
                              className="customButton"
                            >
                              <MdOutlineDelete size="1.5rem" />
                            </IconButton>
                            <Counter
                              count={item.count}
                              itemDispatcher={itemDispatcher}
                              itemId={item.id}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  ))
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ mt: 8 }} variant="h6">
                      No items in your cart
                    </Typography>
                  </Box>
                )}
              </Box>
              <hr style={{ marginTop: "2rem" }} />
              <Grid item spacing={2} container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    // error
                    onChange={(e) => handleCustomerDetailsChange(e)}
                    value={discountCode}
                    name="discountCode"
                    id="discountCode-input"
                    label="Discount Code"
                    placeholder="ABC123"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Payment Method
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={paymentMethod}
                      label="Payment Method"
                      onChange={handleSelectPaymentMethod}
                    >
                      <MenuItem value={"Cash On Delivery"}>
                        Cash On Delivery
                      </MenuItem>
                      <MenuItem value={"Online"}>Online</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box>
                  <Box>
                    <Typography color="h6" gutterBottom>
                      SubTotal: $ {subTotal}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="h6" gutterBottom>
                      GHT/HST: $ {taxAmount}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="h6" gutterBottom>
                      Discount: $ {discount}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="h6" gutterBottom>
                      Delivery Fee: $ {deliveryFee.toFixed(2)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="h6" gutterBottom>
                      Total: $ {totalPrice}
                    </Typography>
                  </Box>

                  <Button
                    disabled={selectedItems.length > 0 ? false : true}
                    variant="contained"
                    size="medium"
                    onClick={() => handleCheckout()}
                    className="customContainedButton"
                  >
                    checkout
                  </Button>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item md={6} lg={7} className="price"></Grid> */}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Cart;
