import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { MdAdd, MdOutlineRemove } from "react-icons/md";
import { useCartItemsContext } from "../../context/CartItemsProvider";
const Counter = ({ count, itemDispatcher, itemId, isAlcoholicDrink }) => {
  const [counterValue, setCounterValue] = useState(count ? count : 0);
  const [canSelectAlcohol, setCanSelectAlcohol] = useState(false);
  const CartItems = useCartItemsContext();
  const handleClick = (action) => {
    let newCounterValue = counterValue;
    switch (action) {
      case "increase":
        newCounterValue += 1;
        setCounterValue(newCounterValue);
        itemDispatcher("increaseItem", itemId);
        break;
      case "decrease":
        if (newCounterValue !== 0) newCounterValue -= 1;
        setCounterValue(newCounterValue);
        itemDispatcher("decreaseItem", itemId);
        break;
      default:
        // Optional: handle unknown actions
        console.warn(`Unknown action: ${action}`);
        break;
    }
  };
  useEffect(() => {
    let matchedItem = CartItems.find((cat) => cat.isFood === true);
    setCanSelectAlcohol(matchedItem);
  }, [CartItems]);
  return (
    <Box sx={{ display: "flex", mb: 1 }}>
      <Button
        disabled={isAlcoholicDrink && !canSelectAlcohol}
        variant="outlined"
        aria-label="decrease"
        onClick={() => handleClick("decrease")}
        className="customButton"
      >
        <MdOutlineRemove fontSize="large" />
      </Button>
      <Box sx={{ display: "flex", alignItems: "center", fontSize: 14, mx: 2 }}>
        {counterValue}
      </Box>
      <Button
        disabled={isAlcoholicDrink && !canSelectAlcohol}
        variant="outlined"
        aria-label="increase"
        onClick={() => handleClick("increase")}
        className="customButton"
      >
        <MdAdd fontSize="large" />
      </Button>
    </Box>
  );
};

export default Counter;
