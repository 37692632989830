import FoodCard from "../../components/FoodCard/FoodCard";
import BeefBurgerImg from "../../assets/MainMenu/BeefBurger.png";
import CheeseBurgerImg from "../../assets/MainMenu/CheeseBurger.png";
import ChickenBurgerImg from "../../assets/MainMenu/ChickenBurger.png";
import DoubleChickenBurgerImg from "../../assets/MainMenu/DoubleChickenBurger.png";
import CaesarSaladImg from "../../assets/Starter/CaesarSalad.png";
import GreenSaladImg from "../../assets/Starter/GreenSalad.png";
import WingImg from "../../assets/Starter/Wing.png";
import FrenchFriesImg from "../../assets/Sides/FrenchFries.png";
import PoutineImg from "../../assets/Sides/Poutine.png";
import CokeImg from "../../assets/Drinks/CokeCola.png";
import GingerAleImg from "../../assets/Drinks/GingerAle.png";
import PepsiImg from "../../assets/Drinks/Pepsi.png";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useCartItemsContext } from "../../context/CartItemsProvider";
import Alert from "@mui/material/Alert";
import axios from "axios";
const starterItemsInit = [
  {
    id: 1,
    title: "Caesar Salad",
    description:
      "Chapped crispy chicken, Crisp romaine lettuce, tossed with garlic croutons, shaved Parmesan cheese,Tomatoes, Caesar sauce",
    price: 13.99,
    rate: 5,
    image: CaesarSaladImg,
    count: 0,
  },
  {
    id: 2,
    title: "Green Salad",
    description:
      "Lettuce, tomatoes, cucumber, olives, cabbage, and special salad dressing.",
    price: 6.99,
    rate: 4,
    image: GreenSaladImg,
    count: 0,
  },
  {
    id: 3,
    title: "Wing",
    description: "Chicken Wings",
    price: 15.99,
    rate: 4,
    image: WingImg,
    count: 0,
  },
];
const mainMenuItemsInit = [
  {
    id: 4,
    title: "Beef Burger",
    description: "Beef patty, tomatoes, pickles, lettuce, and special sauce.",
    price: 12.99,
    rate: 4,
    image: BeefBurgerImg,
    count: 0,
  },
  {
    id: 5,
    title: "Cheese Burger",
    description:
      "Beef patty, tomatoes, pickles, lettuce, cheddar cheese, and special sauce.",
    price: 9.99,
    rate: 5,
    image: CheeseBurgerImg,
    count: 0,
  },
  {
    id: 6,
    title: "Chicken Burger",
    description:
      "Crispy chicken breast, tomatoes, lettuce, pickles, and special sauce.",
    price: 10.49,
    rate: 5,
    image: ChickenBurgerImg,
    count: 0,
  },
  {
    id: 7,
    title: "Double Chicken Burger",
    description:
      "Two layers of crispy chicken breast, Tomatoes, Lettuce, Pickles, Special sauce",
    price: 13.99,
    rate: 4,
    image: DoubleChickenBurgerImg,
    count: 0,
  },
];
const sideItemsInit = [
  {
    id: 8,
    title: "Poutine",
    description: "Fresh cut fries, cheese curds, and veggie brown gravy.",
    price: 9.99,
    rate: 5,
    image: PoutineImg,
    count: 0,
  },
  {
    id: 9,
    title: "French Fries",
    description: "Delicious fries with specials spices",
    price: 4.49,
    rate: 5,
    image: FrenchFriesImg,
    count: 0,
  },
];
const drinkItemsInit = [
  {
    id: 10,
    title: "Coke Cola",
    description: "",
    price: 2.99,
    rate: 5,
    image: CokeImg,
    count: 0,
  },
  {
    id: 11,
    title: "Pepsi",
    description: "",
    price: 2.49,
    rate: 5,
    image: PepsiImg,
    count: 0,
  },
  {
    id: 12,
    title: "Ginger Ale",
    description: "",
    price: 2.49,
    rate: 5,
    image: GingerAleImg,
    count: 0,
  },
];
const Menu = () => {
  const CartItems = useCartItemsContext();
  const [starterItems, setStarterItems] = useState([]);
  const [mainMenuItems, setMainMenuItems] = useState([]);
  const [sideItems, setSideItems] = useState([]);
  const [drinkItems, setDrinkItems] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isClosed, setIsClosed] = useState(false);
  const [noData, setNoData] = useState(false);
  const [shoppingMode, setShoppingMode] = useState(true);
  useEffect(() => {
    // checkOpeningHours();
    getMenu();
    processData();
    if (window.location.pathname === "/staticmenu") setShoppingMode(false);
    else setShoppingMode(true);
  }, []);

  const getMenu = async () => {
    const url = "https://thecookhouse.ca/api/get_menu";
    axios
      .get(url)
      .then((response) => {
        const res = response.data;
        if (!res.data) setNoData(true);
        processData(res);
      })
      .catch((error) => {
        setNoData(true);
        console.log("ERR");
        console.error(error);
      });
  };

  const processData = (data) => {
    let menuItems = data?.objects;
    let menuImages = data?.related_objects;
    if (!menuItems || menuItems.length === 0) {
    }
    // Process the menuItems to add the urls and count attributes
    let updatedMenuItems = menuItems?.map((menuItem) => {
      let matchingUrls = [];
      if (menuItem?.item_data?.image_ids) {
        matchingUrls = menuItem.item_data.image_ids
          ?.map((imageId) => {
            const image = menuImages.find((img) => img.id === imageId);
            return image ? image.image_data.url : null;
          })
          .filter((url) => url !== null);
      }
      // Find the cart item with the same id as the menuItem
      const cartItem = CartItems?.find((item) => item.id === menuItem.id);
      // Return a new object to avoid mutating the original menuItem
      return {
        ...menuItem,
        item_data: {
          ...menuItem.item_data,
          urls: matchingUrls, // Add the urls field
          count: cartItem ? cartItem.count : 0, // Add the count field
        },
      };
    });

    updatedMenuItems = getCategories(updatedMenuItems);
    setMenuData(updatedMenuItems); // Set the processed menuItems to state
  };

  const getCategories = (items) => {
    let categotriesTemp = [];
    let itemsCopy = items;
    // First loop to populate categoriesTemp with the categories
    itemsCopy?.forEach((item) => {
      if (item.type === "CATEGORY") {
        categotriesTemp.push({ name: item.category_data?.name, id: item.id });
      }
    });
    itemsCopy = itemsCopy?.filter((item) => {
      if (item.type === "ITEM" && item.item_data.categories) {
        let matchedCategory = categotriesTemp.find(
          (cat) => cat.id === item.item_data.categories[0].id
        );

        // Return false to remove the item if the category is "Alcoholic Drinks"
        return !(
          matchedCategory && matchedCategory.name === "Alcoholic Drinks"
        );
      }
      return true; // Keep the item if it doesn't match the condition
    });
    itemsCopy?.forEach((item) => {
      if (item.type === "ITEM" && item.item_data.categories) {
        // Find the category object in categoriesTemp that matches the id
        let matchedCategory = categotriesTemp.find(
          (cat) => cat.id === item.item_data.categories[0].id
        );
        if (matchedCategory) item.item_data.category = matchedCategory.name;

        // If a matching category is found and its name is "Alcoholic Drinks"
        if (
          matchedCategory &&
          matchedCategory.name === "Alcohol drinks bottle"
        ) {
          // Add a new field to the item indicating it's an Alcoholic Drink
          item.item_data.isAlcoholicDrink = true;
        } else if (
          matchedCategory &&
          (matchedCategory.name === "Starter" ||
            matchedCategory.name === "Main Menu")
        )
          item.item_data.isFood = true;
        else {
          item.item_data.isAlcoholicDrink = false;
          item.item_data.isFood = false;
        }
      }
    });
    // Sort itemsCopy to prioritize "Main Menu", then "Starter", then others
    itemsCopy?.sort((a, b) => {
      const categoryA = a.item_data.category || "";
      const categoryB = b.item_data.category || "";

      if (categoryA === "Main Menu" && categoryB !== "Main Menu") return -1;
      if (categoryB === "Main Menu" && categoryA !== "Main Menu") return 1;
      if (categoryA === "Starter" && categoryB !== "Starter") return -1;
      if (categoryB === "Starter" && categoryA !== "Starter") return 1;

      return 0; // No sorting change for other items
    });

    setCategories(categotriesTemp);
    return itemsCopy;
  };

  const formatPrice = (price) => {
    return (price / 100).toFixed(2);
  };

  const checkOpeningHours = () => {
    let closed = false;

    // Get current time
    const now = new Date();
    const day = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const hour = now.getHours(); // 0-23 hour format

    // Define Hours for each day of the week
    const openingHours = {
      0: { open: 11, close: 20 }, // Sunday: 11 a.m. – 8 p.m.
      1: null, // Monday: Closed
      2: { open: 11, close: 21 }, // Tuesday: 11 a.m. – 9 p.m.
      3: { open: 11, close: 21 }, // Wednesday: 11 a.m. – 9 p.m.
      4: { open: 11, close: 21 }, // Thursday: 11 a.m. – 9 p.m.
      5: { open: 11, close: 21 }, // Friday: 11 a.m. – 9 p.m.
      6: { open: 11, close: 21 }, // Saturday: 11 a.m. – 9 p.m.
    };

    const hoursToday = openingHours[day];

    // Check if the restaurant is closed today
    if (!hoursToday) {
      closed = true;
    } else {
      const { open, close } = hoursToday;
      if (hour < open || hour >= close) {
        closed = true;
      }
    }

    setIsClosed(closed);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {menuData ? (
        <div>
          <Box sx={{ m: 2 }}>
            <Alert severity="error">
              Please note: Alcoholic items cannot be purchased individually and
              must be ordered with food.
            </Alert>
          </Box>
          <Box sx={{ mb: 6 }}>
            {/* <Typography sx={{ mb: 4, ml: 2 }} variant="h5">
            Main Menu
          </Typography> */}
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {menuData &&
                menuData.map((item) => (
                  <React.Fragment key={item.id}>
                    {item.item_data.name &&
                      item.item_data?.variations &&
                      item.item_data?.variations[0].item_variation_data
                        ?.price_money?.amount && (
                        <Grid
                          key={item.id}
                          item
                          md={6}
                          lg={4}
                          xl={3}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <FoodCard
                            id={item.id}
                            title={item.item_data.name}
                            description={item.item_data.description}
                            price={
                              item.item_data?.variations &&
                              formatPrice(
                                item.item_data?.variations[0]
                                  .item_variation_data?.price_money?.amount
                              )
                            }
                            rate={4}
                            isAlcoholic={
                              item.item_data?.isAlcoholicDrink
                                ? item.item_data?.isAlcoholicDrink
                                : false
                            }
                            isFood={
                              item.item_data?.isFood
                                ? item.item_data?.isFood
                                : false
                            }
                            category={
                              item.item_data?.category
                                ? item.item_data?.category
                                : ""
                            }
                            image={item.item_data.urls[0]}
                            count={item.item_data.count}
                            shoppingMode={shoppingMode}
                          />
                        </Grid>
                      )}
                  </React.Fragment>
                ))}
            </Grid>
          </Box>
          {/* <Box sx={{ mb: 6 }}>
          <Typography sx={{ mb: 4, ml: 2 }} variant="h5">
            Main Menu
          </Typography>
          <Grid container spacing={2}>
            {mainMenuItems?.map((item) => (
              <Grid key={item.id} item md={6} lg={4}>
                <FoodCard
                  id={item.id}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  rate={item.rate}
                  image={item.image}
                  count={item.count}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ mb: 6 }}>
          <Typography sx={{ mb: 4, ml: 2 }} variant="h5">
            Sides
          </Typography>
          <Grid container spacing={2}>
            {sideItems?.map((item) => (
              <Grid key={item.id} item md={6} lg={4}>
                <FoodCard
                  id={item.id}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  rate={item.rate}
                  image={item.image}
                  count={item.count}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          <Typography sx={{ mb: 4, ml: 2 }} variant="h5">
            Drinks
          </Typography>
          <Grid container spacing={2}>
            {drinkItems?.map((item) => (
              <Grid key={item.id} item md={6} lg={4}>
                <FoodCard
                  id={item.id}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  rate={item.rate}
                  image={item.image}
                  count={item.count}
                />
              </Grid>
            ))}
          </Grid>
        </Box> */}
        </div>
      ) : (
        noData && (
          <div>
            <h1>
              Sorry, we have some technical issues! Please try again later
            </h1>
          </div>
        )
      )}
    </Box>
  );
};

export default Menu;
