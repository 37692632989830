import React, { createContext, useContext, useReducer } from "react";

// Create two separate contexts for the state and its dispatcher
const CartItemsContext = createContext(undefined);
const CartItemsContextDispatcher = createContext(null);

const CartItemsProvider = ({ children }) => {
  // Reducer function to handle different actions
  const reducer = (state, action) => {
    switch (action.type) {
      case "increaseItem":
        // Check if the item is already in the cart
        const existingItemIndex = state.findIndex(
          (item) => item.id === action.payload.id
        );

        if (existingItemIndex !== -1) {
          // Item exists, increment the count
          const updatedItems = [...state];
          updatedItems[existingItemIndex] = {
            ...updatedItems[existingItemIndex],
            count: updatedItems[existingItemIndex].count + 1,
          };
          return updatedItems;
        } else {
          // Item does not exist, add it with count set to 1
          return [...state, { ...action.payload, count: 1 }];
        }

      case "decreaseItem":
        // Check if the item is in the cart
        const itemIndex = state.findIndex(
          (item) => item.id === action.payload.id
        );

        if (itemIndex !== -1) {
          const updatedItems = [...state];
          const item = updatedItems[itemIndex];

          if (item.count > 1) {
            // Decrement the count if more than 1
            updatedItems[itemIndex] = {
              ...item,
              count: item.count - 1,
            };
          } else {
            // Remove the item if count is 1
            updatedItems.splice(itemIndex, 1);
          }

          return updatedItems;
        }

        return state; // If item is not found, return current state
      case "removeItem":
        // Remove the item completely from the list, regardless of its count
        return state.filter((item) => item.id !== action.payload.id);
      default:
        throw new Error(`Unknown action type: ${action.type}`);
    }
  };

  // Initialize useReducer with the reducer function and an empty array
  const [cartItems, dispatch] = useReducer(reducer, []); // Initial state is an empty array

  return (
    <CartItemsContext.Provider value={cartItems}>
      <CartItemsContextDispatcher.Provider value={dispatch}>
        {children}
      </CartItemsContextDispatcher.Provider>
    </CartItemsContext.Provider>
  );
};

export default CartItemsProvider;

// Custom hooks for using the contexts
export const useCartItemsContext = () => useContext(CartItemsContext);
export const useCartItemsContextAction = () =>
  useContext(CartItemsContextDispatcher);
