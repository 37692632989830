import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const ConfirmationPage = () => {
  return (
    <Box>
      <Box
        sx={{
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          maxWidth: "700px",
          textAlign: "center",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          margin: "0 auto",
          marginTop: 4,
        }}
      >
        <Typography variant="h6" component="div" sx={{ marginBottom: 1 }}>
          Order Confirmed
        </Typography>
        <Typography variant="body1" component="div">
          🎉 Thank you! Your order has been successfully placed and will be
          delivered soon.
        </Typography>
        <Typography variant="body1" component="div" sx={{ marginTop: 1 }}>
          For any questions or assistance, please feel free to reach out to us.
        </Typography>
        <Typography variant="body1" component="div" sx={{ marginTop: 1 }}>
          📞 +1 709 808 0260
        </Typography>
        <Link to="/menu" style={{ textDecoration: "none" }}>
          <Typography
            variant="body2"
            component="div"
            sx={{
              marginTop: 2,
              color: "primary.main",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Return to Menu
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default ConfirmationPage;
