import { Box, Button, Typography } from "@mui/material";
import { MdLocationPin } from "react-icons/md";
import { Link } from "react-router-dom";
const Contact = () => {
  return (
    <Box>
      <Box
        sx={{
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          maxWidth: "700px",
          textAlign: "center",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          margin: "0 auto",
        }}
      >
        <Typography variant="h6" component="div" sx={{ marginBottom: 1 }}>
          TheCookHouse
        </Typography>
        <Typography variant="body1" component="div">
          📞 +1 709 808 0260
        </Typography>
        <Typography variant="body1" component="div">
          ✉️ thecookhouse27@gmail.com
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 1,
          }}
        >
          <MdLocationPin color="red" size={"1.5rem"} />
          <Typography variant="body1" component="div" sx={{ marginLeft: 0.5 }}>
            27 Cookstown Rd, St. John's, NL A1C 4G7
          </Typography>
        </Box>
        <Link target="_blank" to="https://maps.app.goo.gl/cw26bT5WXQtP9Bzn9">
          <Button className="customButton" variant="text" sx={{ marginTop: 1 }}>
            Show on Google Map
          </Button>
        </Link>

        {/* Hours Section */}
        <Box
          sx={{
            textAlign: "left",
            marginTop: 2,
            padding: 2,
            // backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ marginBottom: 1, textAlign: "center" }}
          >
            Hours
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 1,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Saturday:
              </Typography>
              <Typography variant="body2">11 a.m. – 9 p.m.</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 1,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Sunday:
              </Typography>
              <Typography variant="body2">11 a.m. – 8 p.m.</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 1,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Monday:
              </Typography>
              <Typography variant="body2">Closed</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 1,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Tuesday:
              </Typography>
              <Typography variant="body2">11 a.m. – 9 p.m.</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 1,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Wednesday:
              </Typography>
              <Typography variant="body2">11 a.m. – 9 p.m.</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 1,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Thursday:
              </Typography>
              <Typography variant="body2">11 a.m. – 9 p.m.</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Friday:
              </Typography>
              <Typography variant="body2">11 a.m. – 9 p.m.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
