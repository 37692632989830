import "./Header.css";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { GrMenu } from "react-icons/gr";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Badge } from "@mui/material";
import { useCartItemsContext } from "../../context/CartItemsProvider";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/The-Cook_House_Logo.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    ochre: {
      main: "#E3D026",
      light: "#E9DB5D",
      dark: "#A29415",
      contrastText: "#242105",
    },
  },
});

const drawerWidth = 240;
const navItems = [
  { title: "Menu", path: "/menu" },
  // { title: "About", path: "/" },
  { title: "Contact", path: "/contact" },
];

const Header = (props) => {
  const CartItems = useCartItemsContext();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [badge, setBadge] = useState(0);
  const [shoppingMode, setShoppingMode] = useState(true);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setShoppingMode(props.shoppingMode);
  }, [props.shoppingMode]);

  const onShoppingCartClick = () => {};

  useEffect(() => {
    // Calculate the total count of items in the cart
    let totalItemsCount = CartItems.reduce(
      (accumulator, item) => accumulator + item.count,
      0
    );
    setBadge(totalItemsCount);
  }, [CartItems]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        TheCookHouse
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <Link to={item.path}>
              <ListItemButton sx={{ textAlign: "center", color: "black" }}>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar sx={{ backgroundColor: "#431407 !important" }} component="nav">
          <Toolbar>
            {shoppingMode && (
              <IconButton
                // className="customButton"
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: {
                    sm: "none",
                  },
                }}
              >
                <GrMenu />
              </IconButton>
            )}
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: { sm: "none", xs: "flex" },
                justifyContent: { xs: "center" },
                alignItems: "center",
              }}
            >
              <Link to={shoppingMode ? "/menu" : "/staticmenu"}>
                <IconButton className="customButton">
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ maxWidth: 70, marginRight: 10 }}
                  />
                </IconButton>
              </Link>
              <Typography variant="body" sx={{ my: 2 }}>
                TheCookHouse
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              <Link to={shoppingMode ? "/menu" : "/staticmenu"}>
                <IconButton className="customButton">
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ maxWidth: 70, marginRight: 10 }}
                  />
                </IconButton>
              </Link>
              <Typography variant="body" sx={{ my: 2 }}>
                TheCookHouse
              </Typography>
            </Typography>
            {shoppingMode && (
              <>
                <Link
                  to="/cart"
                  style={{
                    display: {
                      sm: "none",
                      xs: "flex",
                    },
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="show number of items"
                    color="inherit"
                    onClick={() => onShoppingCartClick()}
                  >
                    <Badge badgeContent={badge} color="error">
                      <MdOutlineShoppingCart />
                    </Badge>
                  </IconButton>
                </Link>

                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: shoppingMode ? "block" : "none",
                    },
                  }}
                >
                  {navItems.map((item) => (
                    <Link key={item.title} to={item.path}>
                      <Button sx={{ color: "#fff" }}>{item.title}</Button>
                    </Link>
                  ))}
                </Box>
              </>
            )}
          </Toolbar>
        </AppBar>
        {shoppingMode && (
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        )}
      </Box>
    </div>
  );
};

export default Header;
